import React, { useState, useEffect } from 'react';

import { Drawer, Button, Form, Input } from 'antd';

import { REQUESTS } from '../../api/requests';

import Message from '../../components/Message';

import style from "../style/profile.module.scss";

export default function EditProfile({ onClose, open, getProfile, profile }) {

  const [form] = Form.useForm();

  const [message, setMessage] = useState({
    type: false,
    text:""
  });

  const onFinish = (values) => {

    setMessage(null);

    const { password, confirm_password, username, email, } = values;

    const body = {
      username: username || "",
      email: email || "",
    };

    if (password) {
      body['password'] = password;
      body['confirm_password'] = confirm_password
    }

    function callback(data) {
      onClose();
      getProfile();

      form.setFieldsValue({
        username: data.username,
        email: data.email,
      });
    };

    function errorCallback(error) {
      setMessage({
        type: false,
        text:error
      })
    };

    REQUESTS.PROFILE.UPDATE(body, callback, errorCallback)
  }

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        username: profile.username,
        email: profile.email,
      });
    } else {
      setMessage({
        type: false,
        text:""
      });
      form.resetFields();
    }
  }, [open])


  return (
    <Drawer
      title="Edit Profile"
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        layout='vertical'
        style={{
          maxWidth: 600,
        }}
        onValuesChange={() => setMessage({
          type: false,
          text:""
        })}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input Username!',
            },
          ]}
        >
          <Input placeholder='Username'/>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid Email!',
            },
            {
              required: true,
              message: 'Please input valid Email!',
            },
          ]}
        >
          <Input placeholder='Email'/>
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              min: 8,
              message: 'Password must be at least 8 characters long',
            },
          ]}
        >
          <Input.Password placeholder='Password'/>
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          dependencies={['password']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The new password that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder='Confirm Password'/>
        </Form.Item>
        <Message message={message} />
        <Form.Item className={style['submit-button']}>
          <Button
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
