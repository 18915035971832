import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Dropdown, Avatar, Modal } from 'antd';

import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import EditProfile from './EditProfile';

import styles from "../style/profile.module.scss";

const { confirm } = Modal;

const items = [
  {
    label: 'Profile',
    key: 'Profile',
    icon: <UserOutlined />,
  },
  {
    label: 'Logout',
    key: 'Logout',
    icon: <LogoutOutlined />,
  },
];

export default function Profile() {

  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);

  const [profile, setProfile] = useState({});

  const navigate = useNavigate()

  const onClick = (e) => {
    if (e.key === 'Logout') {
      confirm({
        title: 'Do you want to logout ?',
        okText: 'Logout',
        onOk() {
          navigate('/login');
          localStorage.removeItem("TOKEN")
        },
      });
    } else {
      setOpenProfileDrawer(true)
    }
  }

  const getProfile = () => {
    REQUESTS.PROFILE.GET((data) => {
      setProfile(data);
    })
  };

  useEffect(() => {
    getProfile();
  }, [])

  return (<>
    <Dropdown
      menu={{
        items,
        onClick,
      }}
      trigger={['click']}
      placement="bottom"
      overlayClassName={styles['profile-dropdown']}
    >
      <div className={styles['avatar']} >
        <Avatar icon={<UserOutlined />}/>
        {profile && profile.username}
      </div>
    </Dropdown>
    <EditProfile
      open={openProfileDrawer}
      onClose={() => setOpenProfileDrawer(false)}
      profile={profile}
      getProfile={getProfile}
       />
  </>
  )
}
