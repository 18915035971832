import React, { useState, useEffect } from "react";

import Message from "./Message";

import { REQUESTS } from "../api/requests";

import styles from "./style/macInput.module.scss";

export default function ValidateMacAddress({
  setMacIsValid = () => {},
  mac,
  setMac,
  setDevice,
  disabled = false, 
  placeholder = "Mac address"
}) {
  const [message, setMessage] = useState({
    type: false,
    text:""
  });

  const validateMacAddress = () => {

    setMessage(null);

    if (mac?.length === 0) {
      return;
    }

    function callback(data) {
      if (setDevice) {
        setDevice(data);
      }

      setMessage(
        {
          type: true,
          text:"Mac is valid"
        });

      // setMacIsValid(true);
    }

    function errorCallback(error) {
      // setMacIsValid(false);
      setMessage({
        type: false,
        text:"Mac is not valid"
      });
    }

    REQUESTS.CHECK_DEVICE({ mac }, callback, errorCallback);
  };

  const generateMacFormat = (e) => {
    var mac = e.target.value.replace(/[^0-9a-z]/gi, "");
    var result = "";

    for (var i = 0; i < mac.length; i++) {
      if (i % 2 === 0 && i !== 0) {
        result += ":";
      }
      result += mac[i];
    }
    setMac(result.substring(0, 17));
  };

  useEffect(() => {
    if (mac && mac.length === 17) {
      validateMacAddress();
    } else {
      //  setMacIsValid(false);
      setMessage({
        type: false,
        text:""
      });
    }
  }, [mac]);

  return (
    <div
      className={styles["custom-mac-input-container"]}
    >
      <input
        value={mac}
        onChange={generateMacFormat}
        placeholder={placeholder}
        name="mac"
        id="mac"
        className={styles["mac-inp"]}
        disabled={disabled}
      />
      <Message message={message} style={{height:"max-content",justifyContent:"flex-start"}}/>
    </div>
  );
}

