import React, { useEffect, useState } from 'react';

import { Table, Space, Button, Modal, notification, Tag } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import ValidateMacAddress from '../../components/ValidateMacAddress';

import DevicesModal from './components/DevicesModal';

import styles from "./index.module.scss";

const { confirm } = Modal;

export default function Devices() {
  const [data, setData] = useState([]);

  const [devicesCount, setDdevicesCount] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  const [total, setTotal] = useState(0);

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [current, setCurrent] = useState(null);

  const [sort, setSort] = useState(["createdAt", "DESC"]);

  const [searchMac, setSearchMac] = useState("");

  const [search, setSearch] = useState({});

  const [api, contextHolder] = notification.useNotification();

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => {
        return (currentPage - 1) * limit + index + 1
      }
    },
    {
      title: 'Mac Address',
      dataIndex: 'mac',
      key: 'mac',
    },
    {
      title: 'App Name',
      dataIndex: 'app_name',
      key: 'app_name',
      render: (_text, record) => record?.app_name || "-"
    },
    {
      title: 'IP Address',
      dataIndex: 'ip_address',
      key: 'ip_address',
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      filters: [
        {
          text: 'Tizen',
          value: 'tizen',
        },
        {
          text: 'Webos',
          value: 'webos',
        },
        {
          text: 'Android',
          value: 'android',
        },
        {
          text: 'Vidaa',
          value: 'vidaa',
        },
        {
          text: 'Foxxum',
          value: 'foxxum',
        },
        {
          text: 'Zessen',
          value: 'zessen',
        },
        {
          text: 'Vizio',
          value: 'vizio',
        },
        {
          text: 'Tvos',
          value: 'tvos',
        },
        {
          text: 'IOS',
          value: 'ios',
        },
        {
          text: 'Roku',
          value: 'roku',
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Status',
      dataIndex: 'is_online',
      key: 'is_online',
      filters: [
        {
          text: 'Online',
          value: true,
        },
        {
          text: 'Offline',
          value: false,
        },
      ],
      filterMultiple: false,
      render: (_text, record) => {
        return <Tag color={record?.is_online ? "green" : "#808080b3"} >
          {record?.is_online ? "Online" : "Offline"}
        </Tag>
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return <Space>
          <Button
            type="primary"
            size='small'
            onClick={() => handleTableActionChange("edit", record)}>
            <EditOutlined /></Button>
          <Button
            type="primary"
            size='small'
            onClick={() => handleTableActionChange("delete", record)} danger>
            <DeleteOutlined />
          </Button>
        </Space>
      },
    },
  ];

  const deleteUser = (id) => {
    REQUESTS.DEVICES.DELETE({ id }, () => {
      getDevices();
    })
  }

  const handleTableActionChange = (actionType, item) => {
    switch (actionType) {
      case "edit":
        setOpen(true);
        setCurrent(item.mac);
        break;
      case "delete":
        confirm({
          title: 'Do you want to delete?',
          icon: null,
          onOk() {
            deleteUser(item.id)
            api['success']({
              message: 'Devices',
              description: "Updated",
              duration: 2,
            });
          }
        });
        break;
      default:
        break;
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setLoading(true);

    setCurrentPage(pagination.current);

    setLimit(pagination.pageSize);

    setSearch(filters);

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
  }

  const getDevices = () => {

    setLoading(true);

    const query = {
      page: currentPage,
      limit,
      count: total,
      sort,
    };

    if (search.platform || search.is_online) {
      query['filter'] = {};
      if (search.platform) {
        query.filter['platform'] = search.platform[0];
      }

      if (search.is_online) {
        query.filter['is_online'] = search.is_online[0];
      }
    }

    if (searchMac.length) {
      query['search'] = {};
      query.search['mac'] = searchMac;
    }

    REQUESTS.DEVICES.GET(
      { query: JSON.stringify(query) },
      (data) => {
        if (!data.rows.length) {
          setCurrentPage(1);
        }

        setData(data.rows);
        setTotal(data.count);
        setLoading(false);
      },
      (err) => setLoading(false))
  };

  const getStatistics = () => {
    REQUESTS.DEVICES_COUNT((data) => {
      console.log(data)
      setDdevicesCount(data)
    })
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDevices();
    }, 500);

    return () => clearTimeout(timeoutId);

  }, [limit, currentPage, search, sort, searchMac]);

  useEffect(() => {
    getStatistics();
  }, [])

  return (
    <div>
      <h1>Devices</h1>

      <div className={styles['mac-container']}>
        <ValidateMacAddress
          mac={searchMac}
          setMac={setSearchMac}
          placeholder={"Search Mac address"} />
      </div>

      <div className={styles.statistics}>
        <div>
          <strong>Total: </strong> {devicesCount.total}
        </div>
        <div>
          <strong>Online: </strong>{devicesCount.is_online}
        </div>
        <div>
          <strong>Webos: </strong> {devicesCount.webos}
        </div>
        <div>
          <strong>Tizen: </strong> {devicesCount.tizen}
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        bordered
        size='small'
        scroll={{ x: '100%' }}
      />
      <DevicesModal
        open={open}
        handleCancel={() => {
          setOpen(false);
          setCurrent(null);
        }}
        deviceMac={current}
        getDevices={getDevices}
      />
      {contextHolder}
    </div>
  )
}
