
import React, { useState } from 'react';

import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import styles from "../index.module.scss";

export default function FileChooser({ value = null, onChange = () => { } }) {

    const [file, setFile] = useState(null);

    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
        onChange(e.target.files[0])
    }

    return (<div
        className={styles['file-chooser']}
        style={{border: file || value ? "none" : "1px dashed grey",}}>
        {
            !file && !value ?
                <div>
                    <PlusOutlined  className={styles['plus']}/>
                    <input type='file'
                        onChange={handleChange}
                        accept={["image/png", "image/jpg", "image/jpeg"]}
                        className={styles['file-input']}
                    />
                </div>
                :
                <div>
                    <DeleteOutlined
                        className={styles['delete']}
                        onMouseEnter={(e) => {
                            e.target.style.opacity = 1;
                        }}
                        onMouseLeave={
                            (e) => {
                                e.target.style.opacity = 0;
                            }
                        }
                        onClick={() => {
                            setFile(null);
                            onChange("")
                        }}
                    />
                    <img
                        src={file || value}
                        alt='upload'
                        className={styles['uploaded-image']}
                    />
                </div>
        }
    </div >
    );
}