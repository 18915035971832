import React, { useEffect, useState } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import { Button, Form, Input ,notification} from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../../api/requests';

import Categories from './Categories';

import styles from "../index.module.scss";

export default function ManageCategories() {

    const [liveTv, setLiveTv] = useState([]);

    const [movie, setMovie] = useState([]);
    
    const [series, setSeries] = useState([]);
    
    const [server, setServer] = useState(null);
    
    const [disabled, setDisabled] = useState(true);
    
    const [api, contextHolder] = notification.useNotification();

    const { id } = useParams();

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const getCategories = () => {
        const query = {
            id,
        }

        function callback(data) {
            const filteredData = {
                live: [],
                movie: [],
                series: [],
            };

            for (let i = 0; i < data.length; i++) {
                switch (data[i].type) {
                    case "live":
                        filteredData.live.push(data[i]);
                        break;
                    case "movie":
                        filteredData.movie.push(data[i]);
                        break;

                    case "series":
                        filteredData.series.push(data[i]);
                        break;

                    default:
                        break;
                }
            }

            setLiveTv(filteredData.live);
            setMovie(filteredData.movie);
            setSeries(filteredData.series);
        };

        REQUESTS.MANAGE_CATEGORIES.GET(query, callback)
    }

    const getServer = () => {
        const query = {
            filter: {
                id
            }
        };

        function callback(data) {
            setServer(data.rows[0]);

            if (data.rows[0].username && data.rows[0].password) {
                form.setFieldsValue({
                    username: data.rows[0].username,
                    password: data.rows[0].password
                })
                setDisabled(false);
            }
        }

        REQUESTS.DNS.GET({ query: JSON.stringify(query) }, callback, () => { })
    }

    const onFinish = (values) => {

        const body = {
            id,
            ...values
        };

        function callback(data) {
            getCategories();
            api['success']({
                message: 'Manage Categories',
                description: "Success",
                duration: 2,  
              });
        };

        function errorCallback(err) {
            api['error']({
                message: 'Manage Categories',
                description: err,
                duration: 2,  
              });
        };

        REQUESTS.SYNC_CATEGORIES(body, callback, errorCallback)
    }

    useEffect(() => {
        getCategories();
        getServer();
    }, []);

    return (
        <div>
            <div className={styles['page-top-content']}>
                <ArrowLeftOutlined onClick={() => navigate(-1)} className={styles['go-back-arrow']} />
                <div >
                    <div>
                        <strong> Server name : </strong>{server?.name}
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <strong>Server host : </strong>{server?.host}
                    </div>
                </div>
                <Form
                    form={form}
                    layout='vertical'
                    style={{
                        maxWidth: 600,
                    }}
                    onValuesChange={(values,allValues) => {
                        if(allValues.username  && allValues.password ){
                            setDisabled(false);
                        }else{
                            setDisabled(true);
                        }
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <div className={styles['form-content']}>

                        <Form.Item
                            label="Username"
                            name="username"
                            className={styles['form-content_item']}
                        >
                            <Input placeholder='Username' />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            className={styles['form-content_item']}
                        >
                            <Input.Password placeholder='Password' />
                        </Form.Item>

                        <Form.Item className={'save-button'} >
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={disabled}
                            >
                                Sync Categories
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <div className={styles['categories-container']}>
                <div className={styles['categories-container_categories']}>
                    <h3>TV Categories</h3>
                    <Categories
                        categories={liveTv}
                        setCategories={setLiveTv}
                        getCategories={getCategories}
                    />
                </div>
                <div className={styles['categories-container_categories']}>
                    <h3>Movie Categories</h3>
                    <Categories
                        categories={movie}
                        setCategories={setMovie}
                        getCategories={getCategories}
                    />
                </div>
                <div className={styles['categories-container_categories']}>
                    <h3>Series Categories</h3>
                    <Categories
                        categories={series}
                        setCategories={setSeries}
                        getCategories={getCategories}
                    />
                </div>
            </div>
            {contextHolder}
        </div>
    )
}
