import React, { useEffect, useState } from 'react';

import { Table, Space, Button, Modal, notification, Input } from 'antd';

import { PlusOutlined, DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';

import classNames from 'classnames';

import { REQUESTS } from "../../api/requests";

import ResellerModal from './components/ResellerModal';

import SubResellersModal from './components/SubResellersModal';

import styles from "./index.module.scss";

const { confirm } = Modal;

export default function Resellers() {
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [total, setTotal] = useState(0);

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [actionType, setActionType] = useState("add");

  const [current, setCurrent] = useState(null)

  const [sort, setSort] = useState(["createdAt", "DESC"]);

  const [search, setSearch] = useState("");

  const [subreseller, setSubreseller] = useState();

  const [api, contextHolder] = notification.useNotification();

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => {
        return (currentPage - 1) * limit + index + 1
      }
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'IP Address',
      dataIndex: 'ip_address',
      key: 'ip_address',
    },
    {
      title: 'Type',
      key: 'type',
      filters: [
        {
          text: 'Reseller',
          value: "Reseller",
        },
        {
          text: 'Sub-reseller',
          value: "Sub-reseller",
        },
      ],
      filterMultiple: false,
      render: (_, record) => {
        return record.parent_id ? "Sub-reseller" : "Reseller"
      },
    },
    {
      title: 'Sub-resellers',
      dataIndex: 'sub_reseller_count',
      key: 'sub_reseller_count',
      sorter: true,
    },
    {
      title: 'Devices',
      dataIndex: 'device_count',
      key: 'device_count',
      sorter: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return <Space>
          <Button
            type="primary"
            size='small'
            onClick={(e) => {
              e.stopPropagation();
              handleTableActionChange("edit", record)
            }}>
            <EditOutlined /></Button>
          <Button
            type="primary"
            size='small'
            onClick={(e) => {
              e.stopPropagation();
              handleTableActionChange("delete", record)
            }} danger>
            <DeleteOutlined /></Button>
        </Space>
      },
    },
  ];

  const handleTableActionChange = (actionType, item) => {
    switch (actionType) {

      case "edit":
        setOpen(true);
        setActionType("edit");
        setCurrent(item);
        break;

      case "delete":
        confirm({
          title: 'Do you want to delete?',
          icon: null,
          onOk() {
            REQUESTS.RESELLERS.DELETE({ id: item.id }, () => {
              getResellers();
              api['success']({
                message: 'Reseller',
                description: "Updated",
                duration: 2,
              });
            })
          },
        });
        break;

      default:
        break;
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(filters, 'filters')
    setLoading(true);

    setCurrentPage(pagination.current);

    setLimit(pagination.pageSize);

    setSearch(filters);

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
  }

  const getResellers = () => {
    setLoading(true);

    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort,
    };
    if (search.type) {
      if (search.type[0] === "Reseller") {
        query['filter'] = {
          parent_id: null
        }
      } else {
        query['filter_ne'] = {
          parent_id: null
        }
      }
    }

    if (search.length) {
      query['search'] = {
        email: search,
        username: search,
      }
    }

    function callback(data) {
      if (!data.rows.length) {
        setCurrentPage(1);
      }
      setLoading(false);
      setData(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
    }

    function errorCallback(data) {
      setLoading(false);
    }

    REQUESTS.RESELLERS.GET({ query: JSON.stringify(query) }, callback, errorCallback)
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getResellers()
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentPage, limit, sort, search])

  return (
    <div>
      <h1>Resellers</h1>
      <Button
        onClick={() => {
          setOpen(true);
          setActionType("add")
        }}
        className={styles['add-button']}
      >
        Add New
      </Button>

      <div style={{ width: 200 }}>
        <Input
          placeholder='Search'
          suffix={<SearchOutlined />}
          const={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <Table
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              event.preventDefault();
              setSubreseller(record);
            },
          };
        }}
        rowClassName={(record, index) =>
          classNames(styles['row-click'],
            { [styles["subreseller-data"]]: record.parent_id },
          )
        }
        bordered
        size='small'
        scroll={{ x: '100%' }}
      />
      <ResellerModal
        open={open}
        handleCancel={() => setOpen(false)}
        actionType={actionType}
        current={current}
        getResellers={getResellers}
      />
      <SubResellersModal
        setSubreseller={setSubreseller}
        subreseller={subreseller}
      />
      {contextHolder}
    </div>
  )
}
