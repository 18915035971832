import React, { useState, useEffect } from 'react';

import { Modal, Table, Button, Space, Input } from 'antd';

import { DeleteOutlined, EditOutlined, SearchOutlined,ArrowLeftOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../../api/requests';

import ResellerModal from './ResellerModal';

import styles from '../index.module.scss';

const { confirm } = Modal;

let ids = [];

export default function SubResellersModal({
    subreseller,
    setSubreseller
}) {
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const [total, setTotal] = useState(0);

    const [current, setCurrent] = useState(null)

    const [open, setOpen] = useState(false);

    const [actionType, setActionType] = useState("add");

    const [data, setData] = useState([]);

    const [sort, setSort] = useState(["createdAt", "DESC"]);

    const [search, setSearch] = useState("");

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                return (currentPage - 1) * limit + index + 1
            }
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '  IP Address',
            dataIndex: 'ip_address',
            key: 'ip_address',
        },
        {
            title: 'Sub-resellers',
            dataIndex: 'sub_reseller_count',
            key: 'sub_reseller_count',
            sorter: true,
        },
        {
            title: 'Devices',
            dataIndex: 'device_count',
            key: 'device_count',
            sorter: true,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space>
                    <Button
                        type="primary"
                        size='small'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleTableActionChange("edit", record)
                        }}>
                        <EditOutlined /></Button>
                    <Button
                        type="primary"
                        size='small'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleTableActionChange("delete", record)
                        }} danger>
                        <DeleteOutlined /></Button>
                </Space>
            },
        },
    ];

    const handleTableActionChange = (actionType, item) => {
        switch (actionType) {
            case "edit":
                setOpen(true);
                setActionType("edit");
                setCurrent(item);
                break;
            case "delete":
                confirm({
                    title: 'Do you want to delete?',
                    icon: null,
                    onOk() {
                        REQUESTS.RESELLERS.DELETE({ id: item.id }, () => {
                            getSubReseller()
                        })
                    },
                });
                break;
            default:
                break;
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setLoading(true);

        setCurrentPage(pagination.current);

        setLimit(pagination.pageSize);

        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
    }

    const getSubReseller = () => {
        setLoading(true);

        const query = {
            page: currentPage,
            limit,
            sort,
        };

        if (search.length) {
            query['search'] = {
                username: search,
                email: search,
            }
        }
        
        if (subreseller) {

            REQUESTS.GET_SUBRESELLERS(
                {
                    id: subreseller.id,
                    query: JSON.stringify(query)
                },
                (data) => {
                    setLoading(false);
                    setData(data.rows);
                    setTotal(data.count);
                },
                (err) => setLoading(false))
        }
    }

    const backFunction = () => {
        if (ids.length > 0) { 
            setSubreseller(ids[ids.length - 1])
            ids.pop();
        } else {
            setSubreseller(false)
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (subreseller) {
                getSubReseller();
            }
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [currentPage, limit, sort, search])

    useEffect(() => {
        if (subreseller) {
            getSubReseller();
        }
    }, [subreseller])

    return (<>
        <Modal
            open={subreseller}
            title={<h3><ArrowLeftOutlined onClick={backFunction} /> {subreseller?.username}{"  "}Sub-resellers</h3>}
            onCancel={() => {
                setSubreseller(false);
                setCurrentPage(1);
                setLimit(10);
            }}
            footer={null}
            width={1000}
        >
            <div style={{ width: 200, marginBottom: 10 }}>
                <Input
                    placeholder='Search'
                    suffix={<SearchOutlined />}
                    const={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <Table
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                loaadin={loading}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                rowClassName={styles['row-click']}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            ids.push(subreseller)
                            setSubreseller(record);
                        },
                    };
                }}
                bordered
                size='small'
                scroll={{ x: '100%' }}
            />
        </Modal>
        <ResellerModal
            open={open}
            handleCancel={() => setOpen(false)}
            actionType={actionType}
            current={current}
            getResellers={getSubReseller}
        />
    </>
    )
}
