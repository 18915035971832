import React, { useState, useEffect } from 'react';

import { Button, Form, Input, Modal, notification } from 'antd';

import Message from '../../../components/Message';

import { REQUESTS } from '../../../api/requests';

import styles from "../index.module.scss";

export default function ResellerModal({ open, handleCancel, actionType, current, getResellers }) {
    const [form] = Form.useForm();

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const [api, contextHolder] = notification.useNotification();

    const onFinish = (values) => {
        const { username, email, password, confirm_password } = values;

        setMessage({
            type: false,
            text: ""
        });

        if (actionType === "edit" && current) {
            const body = {
                id: current.id,
                username,
                email,
            };

            if (password) {
                body['password'] = password;
                body['confirm_password'] = confirm_password
            }

            REQUESTS.RESELLERS.UPDATE(body, (data) => {
                handleCancel();
                getResellers();
                api['success']({
                    message: 'Resellers',
                    description: "Updated",
                    duration: 2,
                });
            }, (error) => {
                setMessage({
                    type: false,
                    text: error
                })
            })
        } else {
            const body = {
                username,
                email,
                password
            };

            REQUESTS.RESELLERS.ADD(body, (data) => {
                handleCancel();
                getResellers();
                api['success']({
                    message: 'Reseller',
                    description: "Updated",
                    duration: 2,
                });
            }, (error) => {
                setMessage({
                    type: false,
                    text: error
                })
            })
        }
    }

    useEffect(() => {
        if (open && actionType === "edit" && current) {
            form.setFieldsValue({
                username: current.username,
                email: current.email,
            });
        } else {
            setMessage({
                type: false,
                text: ""
            });
            form.resetFields();
        }
    }, [open])

    return (<>
        <Modal
            open={open}
            title={actionType === "edit" ? <h3>Edit Reseller</h3> : <h3>Add Reseller</h3>}
            // onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
        >
            <Form
                form={form}
                layout='vertical'
                style={{
                    maxWidth: 600,
                }}
                onValuesChange={() => setMessage({
                    type: false,
                    text: ""
                })}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="Username"
                    name="username"
                >
                    <Input placeholder='Username' />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid Email!',
                        },
                        {
                            required: true,
                            message: 'Please input valid Email!',
                        },
                    ]}
                >
                    <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            min: 8,
                            message: 'Password must be at least 8 characters long',
                        },
                    ]}
                >
                    <Input.Password placeholder='Password' />
                </Form.Item>
                {
                    actionType === "edit" && <Form.Item
                        label="Confirm Password"
                        name="confirm_password"
                        dependencies={['password']}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder='Confirm Password' />
                    </Form.Item>
                }

                <Message message={message} />

                <Form.Item className={styles["save-btn"]}>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
        {contextHolder}
    </>
    )
}
