import React from 'react';

import { Checkbox ,notification} from 'antd';

import { REQUESTS } from '../../../api/requests';

import FileChooser from './FileChooser';

import styles from "../index.module.scss";

export default function Categorie({ getCategories, setCategories, categories = [], categorie = [] }) {
    const [api, contextHolder] = notification.useNotification();
    
    const update = (image) => {
        const formData = new FormData();

        formData.append('id', categorie.id)
        formData.append('is_selected', categorie.is_selected);

        if (image) {
            formData.append('category_image', image.fileList ? image.fileList : "");
        }

        REQUESTS.MANAGE_CATEGORIES.UPDATE(formData, (data) => {
            getCategories();
            api['success']({
                message: 'Categories',
                description: "Updated",
                duration: 2,  
              });
        });
    }
    return (
        <div className={styles['category-name-content']}>
            <Checkbox
                onChange={(e) => {
                    categorie.is_selected = e.target.checked;

                    if (e.target.checked) {
                        setCategories([...categories])
                    }

                    update()
                }}

                checked={categorie.is_selected}
            />
            <div style={{ width: 50,height:50 }}>
                <FileChooser
                    onChange={(file) => {
                        if (!file) {
                            categorie.category_image = "";
                            setCategories([...categories]);
                        }
                            update({ fileList: file });
                    }}
                    value={categorie.category_image}
                />
            </div>
            {categorie.category_name}
            {contextHolder}
        </div>
    )
}
