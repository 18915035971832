import CONSTANTS from "../config";

const URLS = {
    // a
    // b
    // c
    CHECK_DEVICE: `${CONSTANTS.API_HOST}admin/devices/check`,
    // d
    DNS: `${CONSTANTS.API_HOST}admin/server`,
    DEVICES: `${CONSTANTS.API_HOST}admin/devices`,
    DEVICES_COUNT: `${CONSTANTS.API_HOST}admin/devices/count`,
    // e
    // f
    // g
    // h
    // i
    // j
    // k
    // l
    LOGIN: `${CONSTANTS.API_HOST}admin/login`,
    // m
    MANAGE_CATEGORIES: `${CONSTANTS.API_HOST}admin/server/categories`,
    // n
    // o
    // p
    PROFILE: `${CONSTANTS.API_HOST}admin/profile`,
    // q
    // r
    RESELLERS: `${CONSTANTS.API_HOST}admin/reseller`,
    // s
    SYNC_CATEGORIES: `${CONSTANTS.API_HOST}admin/server/sync_categories`,
    SUBRESELLERS: `${CONSTANTS.API_HOST}admin/reseller/sub_resellers`,
    // t
    // u
    // v
    // w
    // x
    // y
    // z
};

export default URLS;
