import React, { useState } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  TeamOutlined,
  InboxOutlined,
  FundProjectionScreenOutlined,
} from '@ant-design/icons';

import { Layout, Menu, Button } from 'antd';

import Profile from './components/Profile';

import styles from './index.module.scss';

const { Header, Sider, Content } = Layout;

export default function Dashboard() {
  const navigate = useNavigate();

  const location = useLocation();

  const currentPath = location.pathname.replace("/", "");

  const [collapsed, setCollapsed] = useState(false);

  const [selectedMenuItem, setSelectedMenuItem] = useState(currentPath);

  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical" style={{ height: 150 }} />
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[selectedMenuItem]}
            items={[
              {
                key: 'DNS',
                icon: <InboxOutlined />,
                label: 'DNS',
              },
              {
                key: 'Devices',
                icon: <FundProjectionScreenOutlined />,
                label: 'Devices',
              },
              {
                key: 'Resellers',
                icon: <TeamOutlined />,
                label: 'Resellers',
              },
            ]}
            onClick={({ _item, key }) => {
              setSelectedMenuItem(key);
              navigate(key);
            }}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
            }}
          >
            <div className={styles['header-content']}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                  color: "#fff"
                }}
              />
              <Profile />
            </div>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 0,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}
