import React from 'react';

import { Table } from 'antd';

import Categorie from './Categorie';

export default function Categories({ categories = [], setCategories, getCategories }) {

    return (
        <div>
            <Table
                scroll={{
                    y: 500,
                }}

                pagination={false}
                showHeader={false}
                columns={[
                    {
                        title: "",
                        key: "category_name",
                        dataIndex: "category_name",
                        render: (_text, record) => {
                            return record && <Categorie
                                categorie={record}
                                categories={categories}
                                setCategories={setCategories}
                                getCategories={getCategories}
                            />
                        }
                    }
                ]}
                dataSource={categories.sort((a, b) => (b.is_selected ? 1 : -1))}
                size="small"
                className='table-categories'
            />
        </div>
    )
}
