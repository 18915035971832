import React, { useState, useEffect, useRef } from 'react';

import { Button, Form, Modal, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import ValidateMacAddress from '../../../components/ValidateMacAddress';
import Message from '../../../components/Message';

import { REQUESTS } from '../../../api/requests';

import PlaylistForm from './PlaylistForm';

import scrollToBottom from '../../../utils/scrollToBottom';

import styles from "../index.module.scss";

export default function DevicesModal({ open, handleCancel, getDevices, deviceMac }) {
  const [message, setMessage] = useState({
    type: false,
    text: ""
  });

  const [mac, setMac] = useState("");

  const [device, setDevice] = useState(null);

  const [devicePlaylists, setDevicePlaylists] = useState([])

  const [servers, setServers] = useState([]);

  const [api, contextHolder] = notification.useNotification();

  const devicePlaylistsContainer = useRef(null)

  const update = (playlist, index) => {
    devicePlaylists[index] = { ...playlist }

    console.log(playlist, 'playlist')

    setDevicePlaylists([...devicePlaylists]);
  }

  const addPlaylist = () => {
    devicePlaylists.push({
      action: 'new',
      server_id: null,
      username: "",
      password: "",
    });

    setDevicePlaylists([...devicePlaylists]);

    const el = devicePlaylistsContainer?.current

    if (el) {
      setTimeout(() => {
        scrollToBottom(el)
      }, 1);
    }
  }

  const save = () => {
    setMessage({
      type: false,
      text: ""
    });

    const updatedPlaylists = [];

    for (let i = 0; i < devicePlaylists.length; i++) {
      const { username, password, server_id, action, id } = devicePlaylists[i];
      if (username && password && server_id) {
        updatedPlaylists.push({
          username,
          password,
          server_id,
          action,
        })

        if (action !== 'new') {
          updatedPlaylists[i].id = id;
        }
      }
    }

    if (device) {
      const body = {
        device_id: device.id,
        playlists: updatedPlaylists,
      };

      REQUESTS.DEVICES.UPDATE(body, (data) => {
        handleCancel();
        getDevices();

        api['success']({
          message: 'Devices',
          description: "Updated",
          duration: 2,
        });

      }, (err) => {
        setMessage({
          type: false,
          text: err
        })
      })
    }
  }

  const getServers = () => {
    REQUESTS.DNS.GET({
      query:
        JSON.stringify({
          page: 0,
          pagination: false,
        })
    }, (data) => {
      setServers(data)
    })
  }

  useEffect(() => {
    if (!open) {
      setMac("");
      setDevice(null);
      setDevicePlaylists([]);
    }
  }, [open]);

  useEffect(() => {
    if (deviceMac) {
      setMac(deviceMac);
    } else {
      setMac("");
    }
  }, [deviceMac]);

  useEffect(() => {
    if (device && device.playlists) {
      const list = device.playlists.map(item => {
        return {
          ...item,
          action: 'update',
        }
      })
      setDevicePlaylists(list)
    }
  }, [device]);

  useEffect(() => {
    getServers();
  }, []);

  return (<>
    <Modal
      open={open}
      title={<h3>Edit Device</h3>}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={true}
      bodyStyle={{ height: '449px', }}
    >
      <ValidateMacAddress
        mac={mac}
        setMac={setMac}
        setDevice={setDevice}
        disabled={deviceMac ? true : false}
      />
      <div className={styles['playlists-form-container']} ref={devicePlaylistsContainer}>
        {
          devicePlaylists && devicePlaylists.map((playlist, index) => {
            if (playlist.action !== 'delete') {
              return <PlaylistForm
                key={playlist.id}
                open={open}
                playlist={playlist}
                update={(playlist) => update(playlist, index)}
                servers={servers}
              />
            }
          })
        }
      </div>
      <div className={styles['add-btn-container']}>
        <Button
          onClick={addPlaylist}
          className={'add-button'}
          size='small'
        >
          <PlusOutlined style={{ color: "green" }} />
        </Button>
      </div>
      <Message message={message} />
      <Form.Item className={styles["save-button"]}>
        <Button
          type="primary"
          onClick={save}
        >
          Save
        </Button>
      </Form.Item>
    </Modal>
    {contextHolder}
  </>
  )
}
