import React, { useState, useEffect } from 'react';

import { Button, Form, Input, Modal, notification } from 'antd';

import Message from '../../../components/Message';

import { REQUESTS } from '../../../api/requests';

import styles from "../index.module.scss"

export default function DNSModal({ open, handleCancel, actionType, current, getDNS }) {

    const [form] = Form.useForm();

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const [api, contextHolder] = notification.useNotification();

    const onFinish = (values) => {
        const { name, host } = values;

        setMessage({
            type: false,
            text: ""
        });

        if (actionType === "edit" && current) {
            const body = {
                id: current.id,
                name,
                host,
            };

            REQUESTS.DNS.UPDATE(body, (data) => {
                handleCancel();
                getDNS()
                api['success']({
                    message: 'DNS',
                    description: "Updated",
                    duration: 2,
                });
            }, (error) => {
                setMessage({
                    type: false,
                    text: error
                })
            })
        } else {
            const body = {
                name,
                host,
            };

            REQUESTS.DNS.ADD(body, (data) => {
                handleCancel();
                getDNS();
                api['success']({
                    message: 'DNS',
                    description: "Updated",
                    duration: 2,
                });
            }, (error) => {
                setMessage({
                    type: false,
                    text: error
                })
            })
        }

    }

    useEffect(() => {

        if (open && actionType === "edit" && current) {
            form.setFieldsValue({
                name: current.name,
                host: current.host,
            });
        } else {
            setMessage({
                type: false,
                text: ""
            });
            form.resetFields();
        }
    }, [open])

    return (<>
        <Modal
            open={open}
            title={actionType === "edit" ? <h3>Edit DNS</h3> : <h3>Add DNS</h3>}
            onCancel={handleCancel}
            footer={null}
        >
            <Form
                form={form}
                layout='vertical'
                style={{
                    maxWidth: 600,
                }}
                onValuesChange={() => setMessage({
                    type: false,
                    text: ""
                })}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: actionType === "add" && true,
                        },
                    ]}
                >
                    <Input placeholder='Name' />
                </Form.Item>
                <Form.Item
                    label="Host"
                    name="host"
                    rules={[
                        {
                            required: actionType === "add" && true,
                            message: 'Please enter a URL'
                        },
                        {
                            validator: (rule, value, callback) => {
                                // Regular expression for URL validation
                                const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

                                if (!value || urlPattern.test(value)) {
                                    callback(); // Validation passed
                                } else {
                                    callback('Please enter a valid URL'); // Validation failed
                                }
                            }
                        },
                    ]}
                >
                    <Input placeholder='Host' />
                </Form.Item>

                <Message message={message} />

                <Form.Item className={styles['save-button']}>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
        {contextHolder}
    </>
    )
}
