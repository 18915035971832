import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

import Login from './pages/login';
import DNS from './pages/dns';
import Resellers from './pages/resellers';
import Dashboard from "./layout"
import Devices from './pages/devices';
import ManageCategories from './pages/dns/components/ManageCategories';

import "./index.scss";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="login" />} />
        <Route path='/login' element={<Login />} />

        <Route path="/" element={<Dashboard />} >
          <Route path='DNS' index element={<DNS />} />
          <Route path='Devices' element={<Devices />} />
          <Route path="resellers" element={<Resellers />} />
          <Route path="manage-categories/:id" element={<ManageCategories />} />
        </Route>

        {/* {
          localStorage.getItem("TOKEN") ?
            <Navigate replace to="/dashboard/DNS" /> :
            <Navigate replace to="/login" />
        } */}

        {/* <Route path="*" element={<Navigate replace to="/dashboard/DNS" />} /> */}
      </Routes>
    </>
  );
}

export default App;