import request from "./request";
import URLS from "./url";
import requestQuery from "./requestQuery";

export const REQUESTS = {
  // a
  // b
  // c
  CHECK_DEVICE: (query, callback, errorCallback) => { 
    request("get", URLS.CHECK_DEVICE+ requestQuery(query), {}, callback, errorCallback)
  },
  // d
  DNS: {
    GET: (query, callback, errorCallback) => {
      request("get", URLS.DNS + requestQuery(query), {}, callback, errorCallback)
    },
    UPDATE: (body, callback, errorCallback) => {
      request("put", URLS.DNS, body, callback, errorCallback)
    },
    ADD: (body, callback,errorCallback) => {
      request("post", URLS.DNS, body, callback,errorCallback)
    },
    DELETE: (body, callback) => {
      request("delete", URLS.DNS, body, callback)
    },
  },
  DEVICES: {
    GET: (query, callback) => {
      request("get", URLS.DEVICES + requestQuery(query), {}, callback)
    },
    DELETE: (body, callback) => {
      request("delete", URLS.DEVICES, body, callback)
    },
    UPDATE: (body, callback, errorCallback) => { 
      request("put", URLS.DEVICES, body, callback, errorCallback)
    }
  },
  DEVICES_COUNT: (callback) => {
    request("get", URLS.DEVICES_COUNT, {}, callback)
  },
  // e
  // f
  // g
  GET_SUBRESELLERS: (query, callback, errorCallback) => { 
    request("get", URLS.SUBRESELLERS + requestQuery(query), {}, callback, errorCallback)
  },
  // h
  // i
  // j
  // k
  // l
  LOGIN: (body, callback, errorCallback) => {
    request("post", URLS.LOGIN, body, callback, errorCallback)
  },
  // m
  MANAGE_CATEGORIES: {
    GET: (query,callback) => {
      request("get", URLS.MANAGE_CATEGORIES + requestQuery(query), {}, callback )
    },
    UPDATE:(body, callback, errorCallback) => {
      request("put", URLS.MANAGE_CATEGORIES, body, callback, errorCallback)
    }
  },
  // n
  // o
  // p
  PROFILE: {
    GET: (callback) => {
      request("get", URLS.PROFILE, {}, callback)
    },
    UPDATE: (body, callback, errorCallback) => {
      request("put", URLS.PROFILE, body, callback, errorCallback)
    },
  },
  // q
  // r
  RESELLERS: {
    GET: (query, callback, errorCallback) => {
      request("get", URLS.RESELLERS + requestQuery(query), {}, callback, errorCallback)
    },
    ADD: (body, callback, errorCallback) => {
      request("post", URLS.RESELLERS, body, callback, errorCallback)
    },
    UPDATE: (body, callback, errorCallback) => {
      request("put", URLS.RESELLERS, body, callback, errorCallback)
    },
    DELETE: (body, callback) => {
      request("delete", URLS.RESELLERS, body, callback)
    },
  },
  // s
  SYNC_CATEGORIES: (body,callback, errorCallback) => {
    request("post", URLS.SYNC_CATEGORIES,body, callback, errorCallback)
  },
  // t
  // u
  // v
  // w
  // x
  // y
  // z
};