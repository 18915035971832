import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Form, Input } from 'antd';

import { LockOutlined, UserOutlined, LoginOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import Message from '../../components/Message';

import styles from './index.module.scss';

export default function Login() {
    const [form] = Form.useForm();

    const [message, setMssage] = useState({
        type: false,
        text:""
      })

    const navigate = useNavigate();

    const onFinish = (values) => {
        setMssage({
            type: false,
            text:""
          });

        const body = {
            ...values
        }
        function callback(data) {
            localStorage.setItem('TOKEN', data);
            navigate('/DNS');
        }
        function errorCallback(error) {
            setMssage({
                type: false,
                text:error
              })
        }

        REQUESTS.LOGIN(body, callback, errorCallback)
    };


    return (
        <div className={styles['login-page']}>
            <h1>Login</h1>
            <Form
                form={form}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onValuesChange={()=>setMssage(null)}
                className={styles['login-form']}
            >
                <div className={styles['login-form-items']}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"

                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                            {
                                min: 8,
                                message: 'Password must be at least 8 characters long',
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>

                    <Message message={message} style={{textAlign:"center"}}/>

                    <Form.Item>
                        <Button type="primary" htmlType="submit"
                            icon={<LoginOutlined />}
                            className={styles['login-form-button']}
                        >
                            Log in
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}
