import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Table, Space, Button, Modal, notification, Input } from 'antd';

import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';

import { REQUESTS } from "../../api/requests";

import DNSModal from './components/DNSModal';

import styles from './index.module.scss';

const { confirm } = Modal;

export default function DNS() {
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [total, setTotal] = useState(0);

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [actionType, setActionType] = useState("add");

  const [current, setCurrent] = useState(null);

  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => {
        return (currentPage - 1) * limit + index + 1
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Host',
      dataIndex: 'host',
      key: 'host',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return <Space>
          <Button
            type="primary"
            size='small'
            onClick={() => handleTableActionChange("edit", record)}>
            <EditOutlined /></Button>
          <Button
            type="primary"
            size='small'
            onClick={() => handleTableActionChange("delete", record)} danger>
            <DeleteOutlined /></Button>
          <Button
            size='small'
            onClick={() => handleTableActionChange("manage-categories", record)} >
            manage playlist</Button>
        </Space>
      },
    },
  ];

  const handleTableActionChange = (actionType, item) => {
    switch (actionType) {
      case "edit":
        setOpen(true);
        setActionType("edit");
        setCurrent(item);
        break;
      case "delete":
        confirm({
          title: 'Do you want to delete?',
          icon: null,
          onOk() {
            REQUESTS.DNS.DELETE({ id: item.id }, () => {
              getDNS()
              api['success']({
                message: 'DNS',
                description: "Updated",
                duration: 2,
              });
            })
          },
        });
        break;
      case "manage-categories":
        navigate(`/manage-categories/${item.id}`);
        break;

      default:
        break;
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setLoading(true);

    setCurrentPage(pagination.current);

    setLimit(pagination.pageSize);
  }

  const getDNS = () => {
    setLoading(true);

    const query = {
      query: {
        page: currentPage,
        limit,
        sort: ["id", "DESC"],
      },
    };

    if (searchValue.length) {
      let search = {
        name: searchValue,
        host: searchValue
      }

      query.query["search"] = search;
    }

    query.query = JSON.stringify(query.query);

    function callback(data) {
      if(!data.rows.length){
        setCurrentPage(1);
      }
      setLoading(false);
      setData(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
    }

    function errorCallback(error) {
      setLoading(false);
    }

    REQUESTS.DNS.GET(query, callback, errorCallback)
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getDNS()
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentPage, limit, searchValue])

  return (
    <div>
      <h1>DNS</h1>

      <Button
        onClick={() => {
          setOpen(true);
          setActionType("add")
        }}
        className={styles['add-button']}
      >
        {/* <PlusOutlined /> */}
        Add New
      </Button>
      <div style={{ width: 200 }}>
        <Input
          placeholder="Search"
          suffix={<SearchOutlined />}
          const={searchValue}
          onChange={(e) => setSearchValue(e.target.value)} />
      </div>

      <Table
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{ x: '100%' }}
        bordered
        size='small'
      />
      <DNSModal
        open={open}
        actionType={actionType}
        current={current}
        handleCancel={() => setOpen(false)}
        getDNS={getDNS}
      />
      {contextHolder}
    </div>
  )
}
