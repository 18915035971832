import axios from "axios";

const request = (method, url, body, callback, errorCallback, networkError) => {

    let myAxios = axios.create();

    const TOKEN = localStorage.getItem('TOKEN');

    myAxios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

    myAxios[method](url, method === "delete" ? { data: body } : body)
        .then((response) => {
            if (!response.data.error) {
                callback(response.data);
            } else {
                errorCallback(response.data);
            }
        })
        .catch((error) => {
            if (networkError) {
                networkError(error.message);
            }
            if (error.response && error.response.data) {
                if (errorCallback) {
                    errorCallback(error.response.data);
                }
            }
        });
};

export default request;

// const request = async (method, url, body, callback, errorCallback, networkError) => {
//   try {
//     const TOKEN = localStorage.getItem('TOKEN');
//     const headers = {
//       Authorization: `Bearer ${TOKEN}`,
//     };

//     const config = {
//       method,
//       url,
//       headers,
//     };

//     if (method === "delete") {
//       config.data = body;
//     } else {
//       config.data = body;
//     }

//     const response = await axios(config);

//     if (!response.data.error) {
//       callback(response.data);
//     } else {
//       errorCallback(response.data);
//     }
//   } catch (error) {
//     if (networkError) {
//       networkError(error.message);
//     }
//     if (error.response && error.response.data) {
//       errorCallback(error.response.data);
//     }
//   }
// };


